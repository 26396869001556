.MuiTableCell-paddingCheckbox {
  width: 48px;
  padding: 10px 0 0 13px !important;
}

.MuiInputBase-root {
  background: #fff;
  margin-bottom: 20px;
}

.MuiPaper-elevation1 {
  padding-bottom: 30px;
}

.driver {
  background-color: red;
}

.highlight {
  background-color: #5dd65d;
}

.centered {
  text-align: center !important;
}

.MuiPaper-root {
  overflow-x: scroll;
}

.icon {
  opacity: 0.5;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  margin-right: 16px;
}

.schedule .MuiTableCell-root {
  padding: 5px 8px;
}

.icon:last-child {
  padding-right: 0;
}

.icon:hover {
  opacity: 1;
}

.inactive, .inactive:hover {
  cursor: default !important;
  opacity: 0.2;
}

.big > div {
  background-color: #3f51b5;
  padding: 25px;
}
