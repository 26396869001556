.error {
  color: white !important;
  background: #e45b5b;
  border-radius: 8px;
  padding: 8px 8px;
  text-align: center;
  width: 100%;
  display: table;
  margin-top: -14px;
  position: relative;
}

.decreaseOpacity {
  opacity: 0.2;
}

.first-ride {
  font-size: 20px;
  text-align: center;
  padding: 50px 0;
}
