.hovered {
  min-width: 240px !important;
}

.default {
  min-width: 50px !important;
  background: #eee;
}

.paid {
  color: green !important;
}

.long-ride {
  color: red !important;
}

.invoice-created {
  background: #98de98 !important;
}
