.MarkAsPaid .currentTrack {
  background-color: #ecd07d;
}

.MarkAsPaid .MuiTableCell-body {
  padding: 6px;
}

.notok label{
  color: red !important;
}
