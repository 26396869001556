.pointer {
  cursor: pointer;
}

.MuiMenuItem-root {
  opacity: 0.5;
}

.MuiMenuItem-root:hover {
  opacity: 1;
}

.Mui-selected, .Mui-hover {
  box-shadow: 0px 0px 17px #332;
  z-index: 11;
  opacity: 1;
}

.driver-select {
  margin: 12px;
  width: 117px !important;
}
