.nav-link {
  text-decoration: none;
  color: inherit;
}

.nav-link .MuiSvgIcon-root {
  color: #B49042;
}

.activeRoute .MuiListItem-button {
  background-color: #E3C56F;
}

.inactive-menu > div {
  opacity: 0.4;
}
