.error {
  color: white !important;
  background: #e45b5b;
  border-radius: 8px;
  padding: 8px 8px;
  text-align: center;
  width: 100%;
  display: table;
  position: relative;
}

.button {
  padding: 20px 15px !important;
  background: #E3C56F !important;
  box-shadow: none !important;
}
