.padding {
  padding: 20px !important;
}

.margin {
  margin-top: 20px;
}

.home .MuiTableCell-root {
  padding: 5px 8px;
}

.long-ride {
  color: red !important;
}
