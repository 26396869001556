.add {
  position: absolute !important;
  bottom: 50px;
  right: 50px;
}

.errorr > div {
  background-color: red;
}

.maxWidth {
  max-width: 1800px;
  margin: 0 auto;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
  margin: 0 auto;
}

.MuiFormLabel-root {
  z-index: 1;
}

.paddingRight {
  padding: 0 20px 0 0 !important;
}

.debounce-wrapper {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.debounce-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}